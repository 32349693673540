.master-detail {
    @extend .container-fluid, .row, .no-gutter;
    > div {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-grow: 1;
    }
}

sb-detail {
    width: 100%;
    height: calc(100% - 18px);
    @media(min-width: $screen-sm) {
        padding-left: 2rem;
    }
    .detail-placeholder {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: $gray;
        font-size: 1.4rem;
        i {
            font-size: 3rem;
            margin-bottom: 5px;
        }
    }
    #cardContent {
        display: block;
        flex: 1 1 auto;
    }
}

.card-detail {
    @extend .card-style, .p-0, .mb-5;
    border: 0;
    width: 100%;
    > *:first-child {
        border-top-left-radius: inherit;
        border-top-right-radius: inherit;
    }
}

.card-detail__item-heading {
    @extend .sb-bg-dark, .text-light, .p-3;
    h2 {
        font-size: 1.9rem;
    }
}